import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

import jvcLogo from "../img/jvc-logo.png";
import jvcOsaCanadaLogo from "../img/jvc-osa-canada-logo.png";
import CommitteesRoll from "../components/CommitteesRoll";

// eslint-disable-next-line
export const AboutPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                {title}
              </h2>
              <PageContent className="content" content={content} />
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                Executive Committees
              </h2>
              <CommitteesRoll />
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                Logos
              </h2>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column is-5">
              <figure className="content has-text-centered">
                <img
                  src={jvcLogo}
                  alt="J/Victoria College, Chulipuram, Old Students Association, Canada - JVC OSA Canada"
                  style={{ width: "8em", backgroundColor: "#fff", padding: "0.5em", borderRadius: "1em" }}
                />
                <figcaption>
                  <small>Logo of J/Victoria College, Chulipuram, Sri Lanka</small>
                </figcaption>
              </figure>
          </div>
          <div className="column is-5">
              <figure className="content has-text-centered">
                <img
                  src={jvcOsaCanadaLogo}
                  alt="J/Victoria College, Chulipuram, Old Students Association, Canada - JVC OSA Canada"
                  style={{ width: "11em", backgroundColor: "#fff", padding: "0.5em", borderRadius: "1em" }}
                />
                <figcaption>
                  <small>Logo of J/Victoria College, Chulipuram, Old Students Association, Canada</small>
                </figcaption>
              </figure>
          </div>
        </div>
      </div>
    </section>
  );
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout 
      pageTitle={post.frontmatter.title}
      pageDescription={post.frontmatter.description}
      pageSlug={post.fields.slug}
    >
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;
