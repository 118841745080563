import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'

class CommitteesRollTemplate extends React.Component {
  render() {
    const { data, limit } = this.props
    const { edges: posts } = data.allMarkdownRemark

    // workaround for now until limit via graphql query
    const recordsLimit = limit || (posts||[]).length

    return (
      <div className="columns is-multiline">
        {posts &&
          posts.slice(0, recordsLimit).map(({ node: post }) => (
            <div className="is-parent column is-6" key={post.id}>
              <article
                className={`news-list-item tile is-child box notification ${
                  post.frontmatter.featuredpost ? 'is-featured' : ''
                }`}
              >
                <header>
                  {post.frontmatter.featuredimage ? (
                    <div className="featured-thumbnail">
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: post.frontmatter.featuredimage,
                          alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                          width:
                            post.frontmatter.featuredimage.childImageSharp
                              .gatsbyImageData.width,
                          height:
                            post.frontmatter.featuredimage.childImageSharp
                              .gatsbyImageData.height,
                        }}
                      />
                    </div>
                  ) : null}
                  <p className="post-meta">
                    <Link
                      className="has-text-primary"
                      to={post.fields.slug}
                    >
                      {post.frontmatter.title}
                    </Link>
                  </p>
                </header>
              </article>
            </div>
          ))}
      </div>
    )
  }
}

CommitteesRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}


export default function CommitteesRoll({limit}) {
  return (
    <StaticQuery
      query={graphql`
        query CommitteesRollQuery {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___title] }
            filter: { 
              frontmatter: {
                templateKey: { eq: "custom-page-post" }
                tags: { in: ["Executive Committee"] }
              } 
            }
          ) {
            edges {
              node {
                excerpt(pruneLength: 400)
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  templateKey
                  date(formatString: "MMMM DD, YYYY")
                  featuredpost
                  featuredimage {
                    childImageSharp {
                      gatsbyImageData(
                        width: 150
                        quality: 100
                        layout: CONSTRAINED
                      )

                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data, count) => <CommitteesRollTemplate data={data} count={count} limit={limit} />}
    />
  );
}
